@import 'codemirror/lib/codemirror.css';
@import 'codemirror/theme/dracula.css';
@import 'react-var-ui/dist/index.css';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #11111a;
  color: #ddd;
  overflow: hidden;
  user-select: none;
}

.panel {
  background: #22222a;
}

.menu {
  list-style-type: none;
  padding: 10px 5px;
  display: flex;
  align-items: center;
  margin: 0;
  border-bottom: 2px solid #11111a;

  li {
    margin-right: 10px;
  }

  .logo {
    font-weight: bold;
    padding: 0 10px;

    img {
      max-height: 20px;
      margin-right: 7px;
    }
  }
}

.side {
  border-left: 2px solid #11111a;
  width: 400px;
}

.tabs {
  padding-top: 5px;
  display: flex;
  padding-left: 5px;

  .tab {
    background: #33333a;
    padding: 0 5px;
    margin-right: 5px;
    display: inline-flex;
    align-items: center;

    &.selected {
      background: #44444a;
    }

    button {
      background: none;
      padding: 0;
      line-height: 0;
    }
  }
}

button {
  border: none;
  background: #33333a;
  color: #ddd;
  border-radius: 4px;
  padding: 5px 10px;
  cursor: pointer;

  &:not(:disabled):hover,
  &:not(:disabled).selected {
    background: #44444a;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
}

.v-stack {
  display: flex;
  flex-direction: column;
}

.h-stack {
  display: flex;
  flex-direction: row;
}

.flex {
  flex: 1;
}

.ui {
  width: 100vw;
  height: 100vh;
}

.workspace {
  display: flex;

  .canvas-area {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    canvas {
      max-width: 100%;
      max-height: 100%;
      background: white
        url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAAXNSR0IArs4c6QAAAARzQklUCAgICHwIZIgAAAAxSURBVDhPY2RgYBABYnzgDT5JxlEDGIZJGBBIBvjTCSgMCAG8CW3UAAZwQiIEaBuIACBfCLFwf0bOAAAAAElFTkSuQmCC')
        center center;
      box-shadow: 0 0 15px 5px rgba(0, 0, 0, 0.3);
    }
  }
}

.subpanel {
  padding: 5px 10px;
  margin-bottom: 10px;

  & > strong {
    display: block;
    margin-bottom: 10px;
  }
}

.lower-opacity {
  opacity: 0.5;
}

.input-wrapper {
  display: flex;
  align-items: center;

  & > span {
    margin-right: 5px;
  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.modal {
  background: #22222a;
  box-shadow: 0 0 15px 5px rgba(0, 0, 0, 0.3);
  width: 600px;

  .actions,
  .info {
    padding: 20px;
  }

  .actions {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;

    button {
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        margin-right: 7px;
      }
    }
  }

  .info:not(:last-child) {
    border-bottom: 1px solid #11111a;
  }

  .title {
    padding: 20px;
    font-size: 30px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #11111a;

    .close {
      background: none;
      padding: 0;

      svg {
        height: 25px;
        width: 25px;
      }
    }
  }
}

.logo {
  display: flex;
  align-items: center;

  .badge {
    background: black;
    padding: 0.3em 0.5em;
    border-radius: 5px;
    font-size: 0.6em;
    margin-left: 0.5em;
  }

  img {
    max-height: 1em;
    margin-right: 0.3em;
  }
}

.filter-gallery {
  padding: 20px;
  display: grid;
  grid-template-columns: 200px 1fr;
  min-width: 600px;
  grid-gap: 10px;

  .categories {
    height: 500px;
    width: 200px;
    overflow-y: auto;

    button {
      display: block;
      width: 100%;
      margin-bottom: 5px;
    }
  }

  .filters {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

a {
  color: #aaa;
}

.panel {
  z-index: 1000;
}

.webgl-error {
  padding: 15px;
}

.preview-wrap {
  position: relative;
  z-index: 0;
}

.offset-mark {
  position: absolute;
  z-index: 999;
  top: calc(50% - 5px);
  left: calc(50% - 5px);
  width: 10px;
  height: 10px;
  border: 2px solid white;
  border-radius: 50%;
  cursor: move;
}

.workspace {
  display: grid;
  grid-template-columns: 10fr 12fr;
  flex: 1;
}

.editor {
  position: relative;
  background: #22222a;
}

.react-codemirror2 {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.CodeMirror {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
}

.cm-s-dracula.cm-s-dracula.CodeMirror,
.cm-s-dracula.cm-s-dracula .CodeMirror-gutters {
  background: #22222a !important;
}

.CodeMirror-gutter.errors {
  width: 20px;
}

.CodeMirror-gutter-elt {
  display: flex;
  justify-content: center;
  align-items: center;
}

span.error-icon {
  position: relative;
  display: block;
  width: 13px;
  height: 13px;
  background: url('./error.svg');

  pre {
    position: absolute;
    top: -13px;
    left: 20px;
    padding: 5px;
    width: 300px;
    background: #11111a;
    word-wrap: break-word;
    overflow-wrap: break-word;
    white-space: pre-wrap;
    z-index: 99999;
    pointer-events: none;
    opacity: 0;
  }

  &:hover {
    pre {
      opacity: 1;
    }
  }
}

.section {
  padding: 20px;
}

.editor-settings {
  input,
  select {
    background: #11111a;
    color: white;
    border: 1px solid #22222a;
  }

  label {
    display: grid;
    grid-template-columns: 150px 1fr;
    margin: 5px 0;
  }

  max-height: calc(100vh - 80px);
  overflow-y: auto;
}

.setting,
.select-value {
  background: rgba(0, 0, 0, 0.2);
  padding: 10px;
  margin-bottom: 10px;

  .setting-option {
    display: grid;
    grid-template-columns: 150px 1fr;
    margin: 5px 0;
  }
}

.colorpicker {
  &-color {
    width: 36px;
    height: 14px;
    border-radius: 2px;
  }

  &-swatch {
    padding: 5px;
    background: #fff;
    border-radius: 1px;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
    display: inline-block;
    cursor: pointer;
  }

  &-popover {
    position: absolute;
    z-index: 2;
  }

  &-cover {
    position: fixed;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
  }
}

.react-var-ui {
  height: 100%;
  width: 400px;
  margin: 0;
  --react-var-ui-label-border-color: transparent;
  background: #22222a;
  border-left: 2px solid #11111a;

  &-xy-space {
    height: 150px;
  }
}

.intro {
  padding: 20px;
  padding-top: 0;

  p {
    width: 500px;
  }
}

.uniforms dt {
  font-weight: bold;
}

.overlay.drag {
  opacity: 0;
  pointer-events: none;

  transition: 0.3s ease-in-out all;

  &.dropping {
    opacity: 1;
    pointer-events: all;
  }

  .body {
    display: flex;
    align-items: center;
    flex-direction: column;

    svg {
      display: block;
      width: 5rem;
      height: 5rem;
      margin-bottom: 0.5rem;
    }
  }
}
